<template>
  <div class="container" :style="height">
    <div class="coursehead">
      <dl class="flex-outside">
        <dt>
          <img :src="cont.goodsPicture" alt />
        </dt>
        <dd class="flex-inside">
          <h3>{{cont.goodsName}}</h3>
          <div class="cont">{{cont.goodsAbstract}}</div>
          <div class="price">
            <template v-if="isvip == 2">
              <del>价格：${{cont.goodsPrice}}</del>
              <p>
                VIP价格：
                <em>$0</em>
              </p>
            </template>
            <p v-else>
              价格：
              <em>${{cont.goodsPrice}}</em>
            </p>
            <p>库存：{{cont.goodsInventory}}</p>
          </div>
          <div class="pricebtn">
            <button class="pb-button" v-if="cont.isBuy != 0">已购买</button>
            <template v-else>
              <!-- 没有库存 -->
              <template v-if="cont.goodsInventory == 0">
                <button class="pb-button bc-ff5151 cursor" @click="noticeClick">到货通知</button>
                <button class="pb-button">立即购买</button>
              </template>
              <template v-else>
                <button
                  class="pb-button bc-ff5151"
                  @click="buyCommon"
                  v-if="isvip == 1 || isvip == ''"
                >立即购买</button>
                <button v-else class="pb-button bc-ff5151 cursor" @click="buyVip">立即购买</button>
              </template>
            </template>
          </div>
        </dd>
      </dl>
    </div>
    <div class="coursecont">
      <el-tabs v-model="activeName">
        <el-tab-pane label="课程介绍" name="first">
          <div class="cont">
            <!-- <img :src="cont.goodsInfo" alt style="width:80%;" /> -->
            <iframe
              :src="cont.goodsInfo"
              frameborder="0"
              style="width:100%;height:1500px"
              ref="contentIframe"
            ></iframe>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
// 获取某一个课程的接口
import { selectOne, insertVIP } from "@/assets/js/api";
export default {
  name: "coursedetails",
  data() {
    return {
      height: { height: "" },
      activeName: "first",
      imgBasUrl: this.imgBasUrl,
      id: "",
      cont: "",
      isvip: "",
      isLogin: false,
      isProduction: this.isProduction
    };
  },
  methods: {
    getHight() {
      this.height.height = window.innerHeight - 180 + "px";
    },
    coursecont: function() {
      selectOne({
        id: this.id
      }).then(result => {
        if (result.code == 0) {
          this.cont = result.sysGoods;
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    // 到货通知
    noticeClick: function() {
      this.$confirm(
        "我们会在到货的第一时间给您的注册手机或者邮箱发送通知",
        "通知",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "确定"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消!"
          });
        });
    },
    // vip 立即购买
    buyVip: function() {
      this.$confirm("此课程对vip用户免费，您是vip用户，是否立即购买", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true
      })
        .then(() => {
          insertVIP({
            goodsId: this.id
          }).then(result => {
            if (result.code == 0) {
              this.$message({
                type: "success",
                message: "购买成功，请去手机APP上开启学习吧"
              });
              this.coursecont();
            } else {
              this.$message.error(result.msg);
            }
          });
        })
        .catch(() => {});
    },
    buyCommon: function() {
      if (!localStorage.getItem("token")) {
        this.$message.error("您还未登录，无法购买");
      } else {
        this.$router.push({
          path: "/buynow"
        });
      }
    }
  },

  mounted() {
    this.coursecont();
    // this.$refs.contentIframe.onload = function(event) {
    //   let obj = event.target;
    //   obj.style.height =
    //     obj.contentWindow.document.documentElement.scrollHeight + "px";
    // };
  },
  created() {
    this.getHight();
    window.addEventListener("resize", this.getHight);
    this.id = this.$route.params.id;

    if (localStorage.getItem("token")) {
      this.isvip = JSON.parse(localStorage.getItem("userInfo")).isvip;
      this.isLogin = true;
    }
  },
  destroyed() {
    if (window.loading) {
      window.loading.close();
    }
    window.removeEventListener("resize", this.getHight);
  }
};
</script>
